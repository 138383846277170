.question-info {
    color: #323131;
    font-size: 16px;
}

.key-info {
    text-transform: capitalize;
}

.paging {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.spinner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-top: 20px;
}

.body-manual-check {
    min-height: 200px;
}

.text {
    position: relative;
    width: auto; /* can be fixed as well (ie 400px) */
    width: auto; /* can be fixed as well (ie 400px) */
    z-index: 0;
  }


  .preventSelect {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
@primary-color: #a42083;@secondary-color: #0f276b;