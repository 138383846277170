.landing-half{
    display: flex;
    width: 100%;
}

.first-half{
    width: 60%;
    background: rgb(62, 136, 155);
}

.second-half{
    width: 40%;
}
@primary-color: #a42083;@secondary-color: #0f276b;