@media screen and (min-width: 1030px) {
    .gap {
        height: 20px;
        width: 100%;
        background-color: #eff2f5;
    }

    h1 {
        margin: 10px;
    }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
    .gap {
        height: 20px;
        width: 100%;
        background-color: #eff2f5;
    }
}

@media screen and (max-width: 600px) {
    .gap {
        height: 20px;
        width: 100%;
        background-color: #eff2f5;
    }
    
    h1 {
        margin: 10px;
    }

}
@primary-color: #a42083;@secondary-color: #0f276b;