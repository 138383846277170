@media screen and (min-width: 1030px) {
  .bg-bd-all {
    display: flex;
    flex-direction: column;
    //   justify-content: center;
    min-height: 100vh;
    align-items: center;
    background-color: #f3f3f3;
    font-family: vagRund;
    margin: 0 100px;
    overflow-y: auto;
    padding: 20px 0;
  }

  .bd-card {
    width: auto;
  }

  .sss-header {
    background-color: #fff;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .aware-div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    padding: 0 100px;
  }

  .aware-img {
    width: 180px;
  }
  .footer {
    background-color: black;
    height: 50px;
    padding-bottom: 50px;
  }

  .det-aware {
    text-align: center;
    margin: 20px 0;
}


  .footer-div {
    display: flex;
    justify-self: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
    width: 100%;
  }

  .footer-text {
    color: #fff;
    text-align: center;
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
  .bg-bd-all {
    display: flex;
    flex-direction: column;
    //   justify-content: center;
    height: 100vh;
    align-items: center;
    background-color: #f3f3f3;
    font-family: vagRund;
    margin: 0 20px;
  }

  .bd-card {
    width: 400px;
  }

  .sss-header {
    background-color: #fff;
    min-height: 100%;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .aware-div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 80px;
    padding: 0 10px;
    text-align: center;

  }

  .aware-img {
    width: 160px;

  }

  .det-aware {
    text-align: center;
    margin: 20px 0;
    padding: 0 10px;
}

  .footer {
    background-color: black;
    height: 50px;
    padding-bottom: 50px;
  }

  .footer-div {
    display: flex;
    justify-self: center;
    align-items: center;
    align-self: center;
  }

  .footer-text {
    color: #fff;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .bg-bd-all {
    display: flex;
    flex-direction: column;
    //   justify-content: center;
    height: calc(100vh);
    align-items: center;
    background-color: #fff;
    font-family: vagRund;
    // margin: 0 20px;
  }

  .bd-card {
    width: 400px;
  }

  .sss-header {
    background-color: #fff;
    min-height: 100%;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .aware-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 80px;
    padding: 0 10px;
    text-align: center;

  }

  .aware-img {
    width: 100px;
    margin: 20px 0;
  }

  .det-aware {
      font-size: 12px;
      text-align: center;
  }
 
  .footer {
    background-color: black;
    height: 50px;
    padding-bottom: 50px;
    width: 100%;
  }
  .footer-div {
    display: flex;
    justify-self: center;
    align-items: center;
    align-self: center;
  }

  .footer-text {
    color: #fff;
    text-align: center;
    width: 100%;
  }
}

@primary-color: #a42083;@secondary-color: #0f276b;