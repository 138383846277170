.form-setup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.signup-link {
    display: flex;
    justify-content: center;
}
@primary-color: #a42083;@secondary-color: #0f276b;