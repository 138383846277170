body {
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Tahoma";
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 0cm 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
    padding: 0.5cm;
    // border: 5px red solid;
    height: 256mm;
    outline: 0cm #FFEAEA solid;
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}


#container {
    height: 400px; 
  }
  
  .highcharts-figure, .highcharts-data-table table {
    min-width: 310px; 
    max-width: 800px;
    margin: 1em auto;
  }
  
  .highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #EBEBEB;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }
  .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
  }
  .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
  }
  .highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
  }
  .highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .highcharts-data-table tr:hover {
    background: #f1f7ff;
  }

  .title {
      text-align: center;
  }

  .heading {
      text-align: center;
      font-weight: 700;
      font-size: 22px;
  }

  .domain {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  .sub-heading {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
      font-weight: 600;
      padding: 0 20px;
  }

  .only-one {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    font-weight: 600;
    padding: 0 20px;
  }

  .charts-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
  }

  .image-logo {
      width: auto;
      height: 100px;
      align-items: center;
      justify-content: center;
      align-self: center;
  }

//   .ant-table-thead {
//       display: none;
//   }


.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 16px 4px !important;
    overflow-wrap: break-word;
}

  .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 4px 16px !important;
    overflow-wrap: break-word;
}

.desc-data {
    line-height: 1.8rem;
}

.analysis-hd {
    font-size: 22px;
    font-weight: 600;
    margin: 8px 0px !important;

}

.tb-head {
    font-size: 22px;
    font-weight: 600;
    color: red;
    margin-bottom: 8px !important;
}

.footterr {
    text-align: center;
    background-color: rgba(233, 233, 233, 0.612);
    padding: 2px;
    font-size: 14px;
    font-weight: 600;
}

.ant-table-title {
    padding: 2px 4px !important;
}

.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 4px 4px !important;
    overflow-wrap: break-word;
}

.an-tabe {
    margin-top: 50px;
}

.mr-top {
    margin-top: 35px;
}

g g g text
{
    fill: black;
}
@primary-color: #a42083;@secondary-color: #0f276b;