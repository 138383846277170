@media screen and (min-width: 1030px) {
  .card-container {
    padding: 20px;
    background-color:#eff2f5;
    height: 100vh;
  }
  .card-container p {
    margin: 0;
  }
  .card-container > .ant-tabs-card .ant-tabs-content {
    height: auto;
    margin-top: -16px;
  }
  .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
  }
  .card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
  }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
  .card-container {
    padding: 10px;
    margin: 15px;
    background-color:#eff2f5;

  }

  .ant-table {
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .card-container {
    padding: 5px;
    margin: 5px;
    background-color:#eff2f5;

  }

  .ant-table {
    width: 100% !important;
    overflow-x: auto !important;
  }
}


.highcharts-legend-box .highcharts-shadow {
  display: none !important;
}
@primary-color: #a42083;@secondary-color: #0f276b;