.top-div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.create-setup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: left !important;
    vertical-align: middle;
}
.form-div {
    width: 100%;
    margin-top: 20px;
}
@primary-color: #a42083;@secondary-color: #0f276b;