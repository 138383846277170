@media screen and (min-width: 1030px) {
    .head-butt-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .primary-butt {
        width: 140px;
        height: 45px;
        background-color: #a42083 !important;
        color: #fff;
    }

    .row-row {
        margin: 20px 10px;
    }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
    .head-butt-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .primary-butt {
        width: 140px;
        height: 45px;
        background-color: #a42083 !important;
        color: #fff;
    }

    .row-row {
        margin: 10px;
    }
}

@media screen and (max-width: 600px) {
    .head-butt-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .primary-butt {
        width: 140px;
        height: 45px;
        background-color: #a42083 !important;
        color: #fff;
    }
    .row-row {
        margin: 10px;
    }
}
@primary-color: #a42083;@secondary-color: #0f276b;