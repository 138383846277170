body {
  margin: 0;

}



@font-face {
  font-family: 'vagRund';
  src: local('vagRund'), url(./fonts/VAG_Rounded_Light.ttf) format('truetype');
}
