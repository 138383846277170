.bg-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-items: center;
    background-color: #f3f3f3;
}


.bd-card {
    width: 400px;
}
@primary-color: #a42083;@secondary-color: #0f276b;